import ky from 'ky'
import { nanoid } from 'nanoid'
import { Session } from '@chilipiper/api-type-def'
import { testing } from '@chilipiper/config'
import { captureException, captureMessage, withScope, getCurrentScope } from '@sentry/react'
import { isFireAuth } from '@chilipiper/rollout'
import invariant from 'tiny-invariant'
import * as __session from './session'
import {
  api,
  profiles,
  reportingApi,
  profilesUpload as profilesUploadEndpoint,
  homebase,
} from './endpoint'

import {
  ApiError,
  ErrorCallbackOptions,
  JwtService,
  PatchedTokenResponse,
  SessionResponse,
} from './types'
import { jwtApiV2WithV1Fallback } from './jwtApiV2WithV1Fallback'
import { jwtApiV2, SessionV2 } from './jwtApiV2'

const apiRoot = api('/api/v1/')

const RETRY_LIMIT = 5
const isSessionCall = (url: string) => url.endsWith('v1/session')

// https://floatingapps.atlassian.net/browse/CPP-7657
const isUnauthenticatedSessionCall = async (request: Request, response: Response) =>
  isSessionCall(request.url) && (await response.clone().json())?.type === 'CredentialsRequired'

const isBookingAppLegacyCall = () =>
  window && ['/book', '/router'].some(path => window.location.pathname.includes(path))

// ensures we do not have multiple concurrent calls to refresh token
let refreshJWTPromise: Promise<string | PatchedTokenResponse> | null

let globalJwtApi: JwtService<string> | JwtService<PatchedTokenResponse, SessionV2> | undefined =
  undefined
export const setGlobalJwtApi = (
  api: JwtService<string> | JwtService<PatchedTokenResponse, SessionV2>
) => {
  globalJwtApi = api
}
export const createInstance = (
  apiRoot: string,
  contentType?: string,
  forceRelative = false,
  fallbackJwtApi?: JwtService<string> | JwtService<PatchedTokenResponse, SessionV2>
) => {
  if (!fallbackJwtApi) {
    fallbackJwtApi = isFireAuth() ? jwtApiV2 : jwtApiV2WithV1Fallback
  }

  const instance = ky.create({
    ...(forceRelative
      ? {}
      : {
          prefixUrl:
            process.env.NODE_ENV === 'test'
              ? apiRoot.replace('chilipiper.team', 'chilipiper.io')
              : apiRoot,
          mode: 'cors',
          timeout: false,
        }),
    retry:
      process.env.NODE_ENV === 'test'
        ? { limit: 0, methods: [] }
        : {
            limit: RETRY_LIMIT,
            methods: ['get', 'put', 'delete', 'options'],
          },
    credentials: 'include',
    headers: {
      Accept: '*/*',
      ...(contentType ? { 'content-type': contentType } : {}),
      ...(__session.getSessionId() ? { 'special-session-id': __session.getSessionId() || '' } : {}),
    },
    hooks: {
      beforeRequest: [
        request => {
          const jwtApi = globalJwtApi ?? fallbackJwtApi
          invariant(jwtApi, 'jwtApi is required')

          const requestId = nanoid()
          const token = jwtApi.getToken()
          if (token) {
            request.headers.set('Authorization', `Bearer ${token}`)
          }

          if (__session.getSessionId()) {
            request.headers.set('special-session-id', __session.getSessionId())
          }

          withScope(scope => {
            scope.setTag('request_id', requestId)
          })

          request.headers.set('x-request-id', requestId)
        },
      ],
      afterResponse: [
        async (request, _options, response) => {
          const jwtApi = globalJwtApi ?? fallbackJwtApi
          invariant(jwtApi, 'jwtApi is required')

          try {
            if (
              // When we are on booking-app-legacy we don't need to retry
              !isBookingAppLegacyCall() &&
              ([401, 403].includes(response.status) ||
                (await isUnauthenticatedSessionCall(request, response)))
            ) {
              refreshJWTPromise = refreshJWTPromise ? refreshJWTPromise : jwtApi.refresh()
              const refresh = await refreshJWTPromise
              refreshJWTPromise = null
              request.headers.set(
                'Authorization',
                `Bearer ${typeof refresh === 'string' ? refresh : refresh['jwt-access']}`
              )
              return ky(request)
            }
            return response
          } catch (err) {
            console.error(err)
            return response
          }
        },
      ],
    },
  })

  const sessionId = __session.getSessionId()
  if (sessionId) {
    getCurrentScope().setTag('session_id', sessionId)
  }

  return instance
}

export const session = async (force?: boolean) => {
  try {
    const hasUser = __session.getUser()?.id
    if (hasUser && !force) {
      return Promise.resolve(__session.getSession())
    }
    const body = await get(
      'session',
      process.env.NODE_ENV === 'test'
        ? {
            headers: {
              'special-session-id': testing.sessionId,
            },
          }
        : {}
    ).json<SessionResponse>()

    if ((body as ApiError).error) {
      throw JSON.stringify(body)
    }
    __session.create(body as Session)

    return body as Session
  } catch (err) {
    throw new Error(err as string)
  }
}

export const defaultErrorCallback = (error: ApiError, options?: ErrorCallbackOptions) => {
  if (options?.mute) {
    withScope(scope => {
      scope.setContext('Muted Error', {
        Error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      })
      captureMessage('Muted Error')
    })
    return
  }
  captureException(error, scope => {
    if (options?.context) {
      scope.setContext(options?.context?.name, options?.context?.values)
    }

    if (options?.tag) {
      scope.setTag(options?.tag?.name, options?.tag?.value)
    }

    if (options?.level) {
      scope.setLevel(options?.level)
    }

    if (options?.transactionName) {
      scope.setTransactionName(options?.transactionName)
    }

    return scope
  })
}

const rootInstance = createInstance('')
const rootApiInstance = createInstance(api(''))
const instance = createInstance(apiRoot, 'application/json')
const profilesInstance = createInstance(profiles())
const reportingInstance = createInstance(reportingApi('/api/v1'))
const fireInstance = createInstance(homebase())
const fireConciergeInstance = createInstance(homebase().replace('.na', ''))

export const { post, put, get, patch } = instance

export const del = instance.delete

export const { get: profileGet, post: profilePost, patch: profilePatch } = profilesInstance

export const { get: reportingGet, post: reportingPost } = reportingInstance

export const { get: fireApiGet } = fireInstance

export const { get: fireConcergeApiGet } = fireConciergeInstance

export const { post: profileUpload } = createInstance(profilesUploadEndpoint())

export const { get: rootGet, post: rootPost, delete: rootDelete } = rootInstance

export const { get: rootApiGet, post: rootApiPost, delete: rootApiDelete } = rootApiInstance
